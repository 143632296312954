import { Menu } from "@mui/icons-material";
import { AppBar, Toolbar, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { Button, IconButton } from "gatsby-theme-material-ui";
import React, { memo } from "react";

export const Navigation = memo(() => {
  return (
    <AppBar color="default" position="static">
      <Toolbar>
        <img
          src="https://rectors.bg/images/members/SU.png"
          height="100px"
          style={{ padding: "10px 0" }}
        />
        <Box sx={{ flexGrow: 1 }}>
          <Typography
            component="div"
            sx={{ lineHeight: 1.1 }}
            variant="caption"
          >
            Българско онлайн училище
          </Typography>
          <Typography
            variant="h6"
            component="div"
            sx={{ fontWeight: "bold", lineHeight: 1 }}
          >
            Св. Наум Преславски
          </Typography>
        </Box>
        <IconButton sx={{ display: { xs: "block", sm: "block", md: "none" } }}>
          <Menu />
        </IconButton>
        <Box
          display="flex"
          alignItems="center"
          sx={{ display: { xs: "none", sm: "none", md: "flex" } }}
        >
          <Typography
            component="div"
            sx={{ fontWeight: "bold", color: "primary.dark" }}
          >
            Начало
          </Typography>
          <Typography component="div" sx={{ marginLeft: 5 }}>
            Учебна програма
          </Typography>
          <Typography component="div" sx={{ marginLeft: 5 }}>
            Новини
          </Typography>
          <Typography component="div" sx={{ marginLeft: 5 }}>
            За нас
          </Typography>
          <Typography component="div" sx={{ marginLeft: 5 }}>
            Контакт
          </Typography>
          <Button variant="outlined" color="secondary" sx={{ marginLeft: 5 }}>
            Вход
          </Button>
        </Box>
      </Toolbar>
    </AppBar>
  );
});
