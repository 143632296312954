import {
  AppBar,
  Box,
  Button,
  Grid,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import React from "react";
import Paper from "@mui/material/Paper";
import { Navigation } from "../components/Navigation";

const IndexPage = () => {
  return (
    <div>
      <Navigation />
      <Paper square variant="outlined">
        <Grid container>
          <Grid item xs={12} md={6}>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              sx={{ height: "100%", width: "100%" }}
            >
              <Box sx={{ padding: "0 10%" }}>
                <Typography variant="h3" gutterBottom>
                  <Box
                    component="span"
                    sx={{
                      color: "primary.main",
                      background:
                        "linear-gradient(transparent 82%, rgba(249, 185, 52, 0.3) 0%)",
                    }}
                  >
                    Учете български
                  </Box>{" "}
                  и натрупайте повече опит за реализация в{" "}
                  <Box component="span">България</Box>
                </Typography>
                <Typography variant="h6" color="textSecondary" gutterBottom>
                  Нашата мисия е да разпространяваме образование, което е лесно
                  достъпно и всеки може да научи.
                </Typography>
                <Typography gutterBottom>
                  Присъединете се към над 100 студенти от цял свят и България
                </Typography>
                <Box display="flex" mt={4}>
                  <TextField
                    placeholder="Нашишете вашият емайл"
                    style={{ width: 300 }}
                  />
                  <Box ml={2}>
                    <Button variant="contained" style={{ height: "100%" }}>
                      Запиши се
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <img
              src="https://assets.maccarianagency.com/backgrounds/img1.jpg"
              style={{
                objectFit: "cover",
                // objectPosition: "center 60%",
                width: "100%",
                minHeight: 500,
                // clipPath: "polygon(10% 0%, 100% 0px, 100% 100%, 0% 100%)",
                // shapeOutside: "",
              }}
            />
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
};

export default IndexPage;
